import styled from "styled-components"
import SizedBox from "../../../../../components/sizedbox"
import useWindowSize from "../../../../../hooks/useWindowSize"
import media from "../../../../../utils/custom_media"
import ScreenBreakpoints from "../../../../../utils/screen_breakpoints"
import MovieDetailInfoProvider from "../info_provider/movie_detail_info_provider"
import MovieDetailColumn from "./movie_detail_column"

const MainDiv = styled.div`
width: 100%;
max-width: 1396px;
display: flex;
justify-content: space-between;
align-self: start;
padding-left: 65px;
padding-bottom: 120px;
margin-top: 24px;
${media.tablet}{
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 0;
    margin-top: 0;
}
`
const Container = styled.div`
flex:1;
display: flex;
align-items: flex-start;
justify-content: space-between;
${media.tablet}{
    flex-direction: column;
    :not(:first-child){
        margin-top: 12px;
    }
}
`
const FirstColumnContainer = styled.div`
display: flex;
flex-direction: column;
flex:1;
`

const MovieDetailSectionBottom = () => {
    const windowSize = useWindowSize()
    return (
        <MainDiv>
            <Container>
                <FirstColumnContainer>
                    <MovieDetailColumn
                        model={MovieDetailInfoProvider.columnList[0]}
                    />
                    <SizedBox
                        height="22px"
                    />
                    <MovieDetailColumn
                        model={MovieDetailInfoProvider.columnList[1]}
                    />
                </FirstColumnContainer>
                {
                    windowSize.width < ScreenBreakpoints.tablet &&
                    <SizedBox height="12px" />
                }
                <MovieDetailColumn
                    model={MovieDetailInfoProvider.columnList[2]}
                />
            </Container>
            <Container>
                <MovieDetailColumn
                    model={MovieDetailInfoProvider.columnList[3]}
                />
                <MovieDetailColumn
                    model={MovieDetailInfoProvider.columnList[4]}
                />
            </Container>
        </MainDiv>
    )
}

export default MovieDetailSectionBottom