import IntroductionModel from "../model/introduction_model"
import FirstIntroductionImage from "../../../../../assets/png/first_introduction_image.png"
import SecondIntroductionImage from "../../../../../assets/svg/second_introduction_image.svg"
import ThirdIntroductionImage from "../../../../../assets/png/third_introduction_image.png"

const IntroductionSectionInfoProvider = {
    items: [
        new IntroductionModel(
            "Uneingeschränkter Zugang zu den SRG-Programmen - ohne zusätzliche Kosten",
            "Play Suisse bietet Ihnen das Beste aus den Produktionen und Koproduktionen von SRF, RTS und RTR. Da Play Suisse von der SRG finanziert wird, fallen für die Nutzung des gesamten Angebots keine zusätzlichen Kosten an.",
            FirstIntroductionImage
        ),
        new IntroductionModel(
            "Modernes Fernsehen auf dem Bildschirm Ihrer Wahl dank Login",
            "Wechseln Sie nahtlos zwischen Geräten und suchen Sie dort weiter, wo Sie aufgehört haben, erstellen Sie unterwegs Favoritenlisten und suchen Sie später, personalisierte Inhalte und vieles mehr.",
            SecondIntroductionImage
        ),
        new IntroductionModel(
            "Die Schweiz entdecken - ohne Sprachbarrieren",
            "Neuerscheinungen, Hitprogramme und das Beste aus unseren Archiven. Jeweils in der Originalfassung mit Untertiteln in Deutsch, Französisch, Italienisch und gelegentlich auch in Rätoromanisch oder in verschiedenen Synchronfassungen.",
            ThirdIntroductionImage
        )
    ]
}

export default IntroductionSectionInfoProvider