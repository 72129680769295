import axios from "axios";
import MovieDetailModel from "../pages/home_page/model/movie_detail_model";

class MovieServices {

    async getAllFilms() {
        var response = await axios.get(`${process.env.REACT_APP_API_URL}/page/all`, {
            headers: {
                "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_API_SUBSCRIPTION_KEY}`,
                "Ocp-Apim-Trace": true
            }
        })
        console.log(response.data)
    }

    async getFilmDetails(): Promise<MovieDetailModel> {
        var response = await axios.get(`${process.env.REACT_APP_API_URL}/asset/808576`, {
            headers: {
                "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_API_SUBSCRIPTION_KEY}`,
                "Ocp-Apim-Trace": true
            }
        })
        var responseData = response.data["data"]
        return new MovieDetailModel(
            responseData.name,
            this.convertSecondsToHours(responseData.mediaFiles[0].duration),
            responseData.description,
            responseData.metas.DescriptionLong,
            this.getGenres(responseData.EditorialContentMetaCategoriesDatalab),
            responseData.metas.Year,
            responseData.Directors,
            responseData.ProductionCompanies,
            [],
            responseData.MainCast,
            this.convertImageLink(responseData.images[0].url)
        )
    }

    convertImageLink(imageLink: string): string {
        var subtractedImageLink = imageLink.substring(imageLink.indexOf("/"))
        var newImageLink = `${process.env.REACT_APP_MOVIE_IMAGES_URL}${subtractedImageLink}/width/1452/height/801`
        return newImageLink
    }

    convertSecondsToHours(seconds: number): string {
        var hours = Math.floor(seconds / 3600)
        var minutes = Math.floor(seconds % 3600 / 60);
        return hours >= 1
            ? `${hours}h ${minutes}m`
            : `${minutes}m`
    }

    getGenres(genreData: Array<any>): Array<string> {
        var genres: Array<string> = []
        genreData.forEach(data =>
            genres.push(data.title)
        )
        return genres
    }
}

export default MovieServices