import { ReactComponent as SIcon } from "../../assets/svg/sIcon.svg"
import { ReactComponent as RIcon } from "../../assets/svg/rIcon.svg"
import { ReactComponent as FIcon } from "../../assets/svg/fIcon.svg"
import { ReactComponent as GIcon } from "../../assets/svg/gIcon.svg"
import { ReactComponent as IIcon } from "../../assets/svg/iIcon.svg"
import { ReactComponent as WIcon } from "../../assets/svg/wIcon.svg"
import { ReactComponent as TIcon } from "../../assets/svg/tIcon.svg"
import ProductionTypeIconType from "../../enums/production_type_icon_type"

const ProductionTypeIcon = (type: ProductionTypeIconType) => {

    const getImageSource = () => {
        switch (type) {
            case ProductionTypeIconType.SIcon:
                return SIcon
            case ProductionTypeIconType.RIcon:
                return RIcon
            case ProductionTypeIconType.GIcon:
                return GIcon
            case ProductionTypeIconType.FIcon:
                return FIcon
            case ProductionTypeIconType.IIcon:
                return IIcon
            case ProductionTypeIconType.WIcon:
                return WIcon
            case ProductionTypeIconType.TIcon:
                return TIcon
            default:
                return SIcon
        }
    }

    return (
        getImageSource()
    )
}

export default ProductionTypeIcon