import { hydrate, render } from "react-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom";
import MainApp from './main_app';

const rootElement = document.getElementById("root");
if (rootElement!.hasChildNodes()) {
  hydrate(
    <Router>
      <Route exact path="/">
        <Redirect to="/series/wilder/" />
      </Route>
      <Route exact path="/series/wilder/" component={MainApp} />
      <Redirect to="/series/wilder/" />
    </Router>
    , rootElement);
} else {
  render(
    <Router>
      <Route exact path="/">
        <Redirect to="/series/wilder/" />
      </Route>
      <Route exact path="/series/wilder/" component={MainApp} />
      <Redirect to="/series/wilder/" />
    </Router>
    , rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
