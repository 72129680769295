export default class MostWatchedSectionModel {
    image: string
    title: string
    type: string

    constructor(
        image: string = "",
        title: string = "",
        type: string = "",

    ) {
        this.image = image
        this.title = title
        this.type = type

    }

    static from(json: any) {
        return Object.assign(new MostWatchedSectionModel(), json);
    }
}