import { FC } from "react"
import styled from "styled-components"
import useWindowSize from "../../../../../hooks/useWindowSize"
import media from "../../../../../utils/custom_media"
import ScreenBreakpoints from "../../../../../utils/screen_breakpoints"
import IntroductionModel from "../model/introduction_model"

const MainDiv = styled.div`
width: 316px;
display: flex;
flex-direction: column;
align-items: center;
${media.tablet}{
    width: 100%;
    :not(:first-child){
        margin-top: 36px;
    }
}
`
const TitleText = styled.h3`
font: ${props => props.theme.smallTitleFont};
color: ${props => props.theme.white};
margin-top: 27px;
width: 100%;
${media.tablet}{
    text-align: start; 
    font-size: 20px;
    line-height: 24px;
}
`
const Paragraph = styled.p`
font-size: 16px;
line-height: 22px;
font-weight: 400;
color: ${props => props.theme.white};
margin-top: 12px;
`

interface IntroductionSectionItemProps {
    model: IntroductionModel
    index: Number
}

const IntroductionSectionItem: FC<IntroductionSectionItemProps> = (props) => {
    const windowSize = useWindowSize()
    const isMobile = windowSize.width < ScreenBreakpoints.phone ? true : false

    const getWidthOfImage = () => {
        switch (props.index) {
            case 0:
                return isMobile ? "109px" : "165px"
            case 1:
                return isMobile ? "168.69px" : "263px"
            case 2:
                return isMobile ? "119.5px" : "176px"
            default:
                return "176px"
        }
    }

    return (
        <MainDiv>
            <img src={props.model.image} width={getWidthOfImage()} alt="intro_image" />
            <TitleText>
                {props.model.title}
            </TitleText>
            <Paragraph>
                {props.model.content}
            </Paragraph>
        </MainDiv>
    )
}

export default IntroductionSectionItem