import styled from "styled-components"
import PlaySuisseLogo from "../assets/png/play_suisse_logo.png"
import PlaySuisseMobileLogo from "../assets/png/play_suisse_mobile_logo.png"
import GradientButton from "../components/gradient_button"
import ProductionTypeIcon from "../components/icons/production_type_icons"
import ProductionType from "../components/production_type"
import SizedBox from "../components/sizedbox"
import SvgIcon from "../components/svg_icon"
import ProductionTypeIconType from "../enums/production_type_icon_type"
import useWindowSize from "../hooks/useWindowSize"
import media from "../utils/custom_media"
import ScreenBreakpoints from "../utils/screen_breakpoints"


const MainDiv = styled.div`
height: 99px;
width: 100%;
background: linear-gradient(180deg, ${props => props.theme.blackColor} 26.84%, rgba(0, 0, 0, 0) 92.82%);
display: flex;
align-items: center;
justify-content: space-between;
padding: 0 ${props => props.theme.mainPadding};
position: fixed;
top: 0;
z-index: 1;
${media.phone}{
    padding: 0 15px;
}
`
const LeftContainer = styled.div`
display: flex;
align-items:center;
`
const RightContainer = styled.div`
display: flex;
align-items:center;
`

const DockedMenu = () => {
    const windowSize = useWindowSize()
    const isMobile = windowSize.width < ScreenBreakpoints.phone ? true : false

    return (
        <MainDiv>
            <LeftContainer>
                {
                    isMobile
                        ? <img src={PlaySuisseMobileLogo} width="41px" alt="suisse_mobile_logo" />
                        : <img src={PlaySuisseLogo} width="152px" alt="suisse_logo" />
                }
                <SizedBox
                    width="12px"
                />
                {
                    !isMobile &&
                    <ProductionType
                        width="48px"
                        height="16.7px"
                    >
                        <SvgIcon
                            Icon={ProductionTypeIcon(ProductionTypeIconType.SIcon)}
                            height={8}
                        />
                        <SvgIcon
                            Icon={ProductionTypeIcon(ProductionTypeIconType.RIcon)}
                            height={8}
                        />
                        <SvgIcon
                            Icon={ProductionTypeIcon(ProductionTypeIconType.GIcon)}
                            height={8}
                        />
                        <SizedBox width="3px" />
                        <SvgIcon
                            Icon={ProductionTypeIcon(ProductionTypeIconType.SIcon)}
                            height={8}
                        />
                        <SvgIcon
                            Icon={ProductionTypeIcon(ProductionTypeIconType.SIcon)}
                            height={8}
                        />
                        <SvgIcon
                            Icon={ProductionTypeIcon(ProductionTypeIconType.RIcon)}
                            height={8}
                        />
                    </ProductionType>
                }
            </LeftContainer>
            <RightContainer>
                <GradientButton
                    width={isMobile ? "128.4px" : "175px"}
                    height={isMobile ? "36px" : "40px"}
                    text="Jetzt anmelden"
                    fontSize={isMobile ? "12px" : "16px"}
                />
                <SizedBox
                    width="18px"
                />
                <GradientButton
                    width={isMobile ? "79px" : "106px"}
                    height={isMobile ? "36px" : "40px"}
                    text="Einloggen"
                    isTransparent={true}
                    fontSize={isMobile ? "12px" : "16px"}
                />
            </RightContainer>
        </MainDiv>
    )
}

export default DockedMenu