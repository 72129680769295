import { createContext, FC, useState } from "react";

export const MovieDetailStatusContext = createContext({
    isMovieDetailVisible: false,
    toggleDetailVisibility: () => { }
}
)

interface MovieDetailStatusProviderProps {
    children: React.ReactNode
}

export const MovieDetailStatusProvider: FC<MovieDetailStatusProviderProps> = (props) => {
    const [isMovieDetailVisible, setMovieDetailVisibility] = useState(false)

    const toggleDetailVisibility = () => {
        setMovieDetailVisibility(!isMovieDetailVisible)
    }

    return (
        <MovieDetailStatusContext.Provider
            value={{
                isMovieDetailVisible: isMovieDetailVisible,
                toggleDetailVisibility: () => toggleDetailVisibility()
            }}
        >
            {props.children}
        </MovieDetailStatusContext.Provider>
    )
}