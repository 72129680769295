import styled from "styled-components"
import media from "../../../../../utils/custom_media"
import FooterSectionInfoProvider from "../info_provider/footer_section_info_provider"

const MainDiv = styled.div`
display: flex;
text-align: center;
align-items: center;
justify-content: space-between;
width: 100%;
${media.desktop}{
    flex-direction: column;
}
`

const LeftEmptyDiv = styled.div`
width: 201px;
`

const TopContainer = styled.div`
display: flex;
align-items: center;
${media.largeScreen}{
    flex-direction: column;
    row-gap: 16px;
}
${media.phone}{
    width: 100%;
    justify-content: space-between;
}
`
const TopText = styled.span`
font-family: ${props => props.theme.serifFont};
opacity: 0.8;
font-size: 20px;
line-height: 30px;
font-weight: 400;
color: ${props => props.theme.white};
cursor: pointer;
${media.largeScreen}{
    font-size: 16px;
}
:not(:first-child){
    margin-left: 60px;
    ${media.largeScreen}{
    margin-left: 0;
}
}
${media.phone}{
    font-size: 17px;
    :not(:first-child){
    margin-left: 0;
}
}
:hover{
    opacity: 1;
}
`

const RightLanguagesContainer = styled.ul`
margin: 0;
display: flex;
padding: 0;
list-style: none;
${media.desktop}{
    margin-top: 16px;
}
`
const LanguageButton = styled.button`
color: white;
opacity: 0.7;
padding: 8px 16px;
border-radius: 3px;
background-color: transparent;
cursor: pointer;
:hover{
    opacity: 1;
}
`


const FooterSectionTop = () => {

    const buildLanguageText = (text: string) => {
        return (
            <li>
                <LanguageButton>
                    {text}
                </LanguageButton>
            </li>
        )
    }


    return (
        <MainDiv>
            <LeftEmptyDiv />
            <TopContainer>
                <TopText>
                    Contact
                </TopText>
                <TopText>
                    Impressum
                </TopText>
                <TopText>
                    Datenschutz
                </TopText>
                <TopText>
                    FAQ
                </TopText>
            </TopContainer>
            <RightLanguagesContainer>
                {
                    FooterSectionInfoProvider.languages.map((e) =>
                        buildLanguageText(e)
                    )
                }
            </RightLanguagesContainer>
        </MainDiv>



    )
}

export default FooterSectionTop