import React from "react"
import styled from "styled-components"
import ProductionTypeIcon from "../../../../../components/icons/production_type_icons"
import ProductionType from "../../../../../components/production_type"
import SizedBox from "../../../../../components/sizedbox"
import SvgIcon from "../../../../../components/svg_icon"
import ProductionTypeIconType from "../../../../../enums/production_type_icon_type"
import media from "../../../../../utils/custom_media"
import FooterSectionInfoProvider from "../info_provider/footer_section_info_provider"

const MainDiv = styled.div`
display: flex;
flex-direction:column;
align-items: center;
margin-top: 46px;
${media.phone}{
    margin-top: 36px;
}
`
const BottomParagraph = styled.p`
margin-top: 16px;
font: ${props => props.theme.bodyFont};
color: ${props => props.theme.white};
white-space: break-spaces;
text-align: center;
overflow: hidden;
${media.phone}{
    font-size: 14px;
    line-height: 16px;
}
`

const FooterSectionBottom = () => {
    return (
        <MainDiv>
            <ProductionType
                width="92.84px"
                height="32px"
            >
                <SvgIcon
                    Icon={ProductionTypeIcon(ProductionTypeIconType.SIcon)}
                    height={15.47}
                />
                <SizedBox width="1px" />
                <SvgIcon
                    Icon={ProductionTypeIcon(ProductionTypeIconType.RIcon)}
                    height={15.47}
                />
                <SvgIcon
                    Icon={ProductionTypeIcon(ProductionTypeIconType.GIcon)}
                    height={15.47}
                />
                <SizedBox width="5.62px" />
                <SvgIcon
                    Icon={ProductionTypeIcon(ProductionTypeIconType.SIcon)}
                    height={15.47}
                />
                <SizedBox width="1px" />
                <SvgIcon
                    Icon={ProductionTypeIcon(ProductionTypeIconType.SIcon)}
                    height={15.47}
                />
                <SizedBox width="1px" />
                <SvgIcon
                    Icon={ProductionTypeIcon(ProductionTypeIconType.RIcon)}
                    height={15.47}
                />
            </ProductionType>
            <BottomParagraph>
                {FooterSectionInfoProvider.footerSectionParagraph}
            </BottomParagraph>
        </MainDiv>
    )
}

export default FooterSectionBottom