import { createContext, FC, useEffect, useState } from "react";
import MovieDetailModel from "../pages/home_page/model/movie_detail_model";
import MovieServices from "../services/movie_services";

export const MovieDetailContext = createContext({
    movieDetail: new MovieDetailModel(),
}
)

interface MovieDetailProviderProps {
    children: React.ReactNode
}

export const MovieDetailProvider: FC<MovieDetailProviderProps> = (props) => {
    const [movieDetail, setMovieDetail] = useState<MovieDetailModel>(new MovieDetailModel())
    const movieDetailService = new MovieServices()

    // useEffect(() => {
    //     getAllFilms()
    // }, [])

    // const getAllFilms = async () => {
    //     await movieDetailService.getAllFilms()
    // }
    // const getFilmDetails = async () => {
    //     var newMovieDetail = await movieDetailService.getFilmDetails()
    //     console.log(newMovieDetail)
    //     setMovieDetail(newMovieDetail)
    // }

    return (
        <MovieDetailContext.Provider
            value={{
                movieDetail: movieDetail,
            }}
        >
            {props.children}
        </MovieDetailContext.Provider>
    )
}