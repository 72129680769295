import { ThemeProvider } from "styled-components"
import App from "./App"
import { MovieDetailProvider } from "./providers/movie_detail_provider"
import { MovieDetailStatusProvider } from "./providers/movie_detail_status_provider"
import theme from "./utils/theme/theme"

const MainApp = () => {
    return (
        <MovieDetailProvider>
            <MovieDetailStatusProvider>
                <ThemeProvider theme={theme}>
                    <App />
                </ThemeProvider>
            </MovieDetailStatusProvider>
        </MovieDetailProvider>
    )
}

export default MainApp