import styled from "styled-components"
import media from "../../../../../utils/custom_media"

const MainDiv = styled.div`
width: 100%;
background: linear-gradient(0deg, rgba(0, 0, 0, 0) 20.46%, #000000 100%);
padding-top: 42px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
${media.phone}{
    padding: 0 15px;
    background: linear-gradient(0deg,rgba(0,0,0,0) 0.46%,#000000 54%);
}
`
const Title = styled.h2`
font: ${props => props.theme.normalTitleFont};
color: ${props => props.theme.white};
${media.phone}{
    font-size: 24px;
}
`
const Paragraph = styled.span`
text-align: center;
margin-top: 25px;
font-size: 16px;
font-weight: 400;
line-height: 22px;
color: ${props => props.theme.white};
white-space: break-spaces;
${media.phone}{
    text-align: start;
    margin-top: 9px;
    opacity: 0.7;
}
`

const IntroductionSectionTop = () => {
    return (
        <MainDiv>
            <Title>
                Die neue Streaming-Plattform in der Schweiz
            </Title>
            <Paragraph>
                {`Hier finden Sie die besten Schweizer Filme, Serien und Dokumentationen - ohne zusätzliche Kosten. Dazu kommen:
personalisierte Film- und Serienvorschläge und spezielle Kollektionen, kuratiert für Sie.`}
            </Paragraph>
        </MainDiv>
    )

}

export default IntroductionSectionTop