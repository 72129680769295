import styled from "styled-components"
import SocialMediaIcon from "../../../../../components/icons/social_media_icon"
import SizedBox from "../../../../../components/sizedbox"
import SocialMediaIconType from "../../../../../enums/social_media_icon_type"
import GooglePlayImage from "../../../../../assets/png/google_play_image.png"
import BlueTvImage from "../../../../../assets/png/blue_tv_image.png"
import AppStoreImage from "../../../../../assets/png/app_store_image.png"
import ProductionType from "../../../../../components/production_type"
import ProductionTypeIcon from "../../../../../components/icons/production_type_icons"
import ProductionTypeIconType from "../../../../../enums/production_type_icon_type"
import SvgIcon from "../../../../../components/svg_icon"
import media from "../../../../../utils/custom_media"
import useWindowSize from "../../../../../hooks/useWindowSize"
import ScreenBreakpoints from "../../../../../utils/screen_breakpoints"

const MainDiv = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
margin-top: 47px;
${media.phone}{
    margin-top: 35px;
    flex-direction: column;
}
`
const LeftContainer = styled.div`
display: flex;
align-items: center;
`
const MiddleContainer = styled.div`
display: flex;
align-items: center;
margin-left: 117px;
${media.phone}{
    margin-left: 0;
    margin-top: 33px;
    flex-direction: column;
}
img{
    ${media.phone}{
        :not(:first-child){
            margin-top: 12px;
        }
    }
}
`
const RightContainer = styled.div`
display: flex;
align-items: center;
${media.phone}{
    margin-top: 40px;
}
`
const BusinessUnitMain = styled.div`
width: 48px;
height: 38px;
background: rgba(83, 83, 83, 0.7);
padding: 3px 12px;
opacity: 0.7;
margin: 0 4px;
color: white;
display: block;
border-radius: 3px;
text-align: center;
font-size: 13px;
line-height: 16px;
cursor: pointer;
:hover{
    opacity: 1;
}
`

const FooterSectionMiddle = () => {
    const windowSize = useWindowSize()
    const isMobile = windowSize.width < ScreenBreakpoints.phone ? true : false


    const buildBusinessUnit = (text: string) => {
        return (
            <BusinessUnitMain>
                {`Play ${text}`}
            </BusinessUnitMain>
        )
    }

    return (
        <MainDiv>
            <LeftContainer>
                <SocialMediaIcon
                    iconType={SocialMediaIconType.Facebook_outlined}
                />
                <SizedBox
                    width="8px"
                />
                <SocialMediaIcon
                    iconType={SocialMediaIconType.Instagram_outlined}
                />
                <SizedBox
                    width="8px"
                />
                <SocialMediaIcon
                    iconType={SocialMediaIconType.Youtube_outlined}
                />
            </LeftContainer>
            <MiddleContainer>
                <img src={AppStoreImage} width={isMobile ? "174px" : "134.62px"} alt="app_store_image" />
                {
                    !isMobile &&
                    <SizedBox
                        width="8.34px"
                    />
                }
                <img src={GooglePlayImage} width={isMobile ? "174px" : "134.62px"} alt="google_play_image" />
                {
                    !isMobile &&
                    <SizedBox
                        width="14.38px"
                    />
                }
                <img src={BlueTvImage} width={isMobile ? "116px" : "80px"} alt="bluetv_image" />
            </MiddleContainer>
            <RightContainer>
                {buildBusinessUnit("SRF")}
                {buildBusinessUnit("RTS")}
                {buildBusinessUnit("RSI")}
                {buildBusinessUnit("RTR")}
            </RightContainer>
        </MainDiv>
    )
}

export default FooterSectionMiddle