import styled from "styled-components"
import IntroductionBackgroundImage from "../../../../assets/jpg/introduction_background.jpg"
import IntroductionMobileBackgroundImage from "../../../../assets/jpg/introduction_mobile_background.jpg"
import media from "../../../../utils/custom_media"
import IntroductionSectionBottom from "./components/introduction_section_bottom"
import IntroductionSectionTop from "./components/introduction_section_top"

const MainDiv = styled.section`
width: 100%;
background: linear-gradient(180deg, rgba(0, 0, 0, 0) 20.46%, #000000 100%), url(${IntroductionBackgroundImage});
background-size: cover;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
${media.phone}{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 20.67%, rgba(0, 0, 0, 0.63) 59.2%, #000000 100%), url(${IntroductionMobileBackgroundImage});
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: right;
    margin-top: 36px;
}
`
const CenterContainer = styled.div`
max-width: 1440px;
width: 100%;
display: flex;
flex-direction: column;
padding-bottom: 115px;
${media.phone}{
    padding-bottom: 0;
}
`

const IntroductionSectionMain = () => {
    return (
        <MainDiv>
            <IntroductionSectionTop />
            <CenterContainer>
                <IntroductionSectionBottom />
            </CenterContainer>
        </MainDiv>
    )
}

export default IntroductionSectionMain