import styled from 'styled-components';
import PageLayout from './components/page_layout';
import DockedMenu from './docked_menu/docked_menu';
import FooterSectionMain from './pages/home_page/sections/footer_section/footer_section_main';
import HeaderSectionMain from './pages/home_page/sections/header_section/header_section_main';
import IntroductionSectionMain from './pages/home_page/sections/introduction_section/introduction_section_main';
import MostWatchedSectionMain from './pages/home_page/sections/most_watched_section/most_watched_section_main';
import MovieDetailSectionMain from './pages/home_page/sections/movie_detail_section/movie_detail_section_main';
import ReviewSectionMain from './pages/home_page/sections/review_section/review_section_main';

const MainDiv = styled.div`
display: flex;
flex-direction: column;
overflow: hidden scroll;
align-items: center;
width: 100vw;
min-height: 100vh;
background: ${props => props.theme.blackColor};
`

const App = () => {
  return (
    <MainDiv>
      <DockedMenu />
      <HeaderSectionMain />
      <PageLayout>
        <MovieDetailSectionMain />
      </PageLayout>
      <IntroductionSectionMain />
      <PageLayout>
        <MostWatchedSectionMain />
        {/* <ReviewSectionMain /> */}
      </PageLayout>
      <FooterSectionMain />
    </MainDiv>
  )
}

export default App;
