import styled from "styled-components"
import media from "../../../../utils/custom_media"
import MostWatchedItem from "./components/most_watched_item"
import MostWatchedSectionInfoProvider from "./info_provider/most_watched_section_info_provider"

const MainDiv = styled.div`
display: flex;
flex-direction: column;
padding: 0 15px;
margin-top: 48px;
${media.phone}{
    margin-top: 36px;
}
`
const TitleText = styled.h2`  
font: ${props => props.theme.normalTitleFont};
color: ${props => props.theme.white};
${media.phone}{
    font-size: 24px;
}
`
const FilmContainer = styled.div`
margin-top: 20px;
display: grid;
grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
place-items: center;
gap: 15px;
${media.phone}{
    margin-top: 13px;
    gap: 9px;
    grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
}
`

const MostWatchedSectionMain = () => {
    return (
        <MainDiv>
            <TitleText>
                Meist gesehen in der Schweiz
            </TitleText>
            <FilmContainer>
                {
                    MostWatchedSectionInfoProvider.itemList.map(model =>
                        <MostWatchedItem
                            model={model}
                        />
                    )
                }
            </FilmContainer>
        </MainDiv>
    )
}

export default MostWatchedSectionMain