import React from "react"
import styled from "styled-components"
import GradientButton from "../../../../components/gradient_button"
import SizedBox from "../../../../components/sizedbox"
import useWindowSize from "../../../../hooks/useWindowSize"
import { MovieDetailStatusContext } from "../../../../providers/movie_detail_status_provider"
import media from "../../../../utils/custom_media"
import ScreenBreakpoints from "../../../../utils/screen_breakpoints"
import MovieDetailSectionBottom from "./components/movie_detail_section_bottom"
import MovieDetailSectionTop from "./components/movie_detail_section_top"

const MainDiv = styled.div`
padding-top: 50px;
display: flex;
flex-direction: column;
align-items: center;
${media.tablet}{
    padding-top: 0;
}
`

const MovieDetailSectionMain = () => {
    const detailStatusContext = React.useContext(MovieDetailStatusContext)
    const windowSize = useWindowSize()
    const isMobile = windowSize.width < ScreenBreakpoints.phone ? true : false
    return (
        <MainDiv>
            {
                isMobile &&
                <SizedBox
                    height="40px"
                />
            }
            <GradientButton
                width={isMobile ? "calc(100% - 30px)" : "249px"}
                height={isMobile ? "56px" : "60px"}
                text="Jetzt anmelden"
                alignSelf="center"
                fontSize={isMobile ? "20px" : "16px"}
                fontWeight={700}
            />
            {
                detailStatusContext.isMovieDetailVisible &&
                <>
                    <MovieDetailSectionTop />
                    <MovieDetailSectionBottom />
                </>
            }
        </MainDiv>
    )
}

export default MovieDetailSectionMain