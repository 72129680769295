import MostWatchedSectionModel from "../model/most_watched_section_model"
import FirstFilmImage from "../../../../../assets/png/frieden_film_image.png"
import SecondFilmImage from "../../../../../assets/png/doppelleben_film_image.png"
import ThirdFilmImage from "../../../../../assets/png/bestatter_film_image.png"
import FourthFilmImage from "../../../../../assets/png/cellule_film_image.png"
import FifthFilmImage from "../../../../../assets/png/secret_film_image.png"
import SixthFilmImage from "../../../../../assets/png/horizon_film_image.png"


const MostWatchedSectionInfoProvider = {
    itemList: [
        new MostWatchedSectionModel(FirstFilmImage, "Frieden",),
        new MostWatchedSectionModel(SecondFilmImage, "Doppelleben", "Series • Drama"),
        new MostWatchedSectionModel(ThirdFilmImage, "Der Bestatter",),
        new MostWatchedSectionModel(FourthFilmImage, "Cellule De Crise",),
        new MostWatchedSectionModel(FifthFilmImage, "L'heure du Secret",),
        new MostWatchedSectionModel(SixthFilmImage, "Station Horizon",),
    ]
}

export default MostWatchedSectionInfoProvider