import styled from "styled-components"
import media from "../../../../../utils/custom_media"
import IntroductionSectionInfoProvider from "../info_provider/introduction_section_info_provider"
import IntroductionSectionItem from "./introduction_section_item"

const MainDiv = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
margin-top: 100px;
padding: 0 64px;
flex-wrap: wrap;
flex:1;
${media.tablet}{
    justify-content: center;
    row-gap: 10px;
}
${media.phone}{
    padding: 0 15px;
    margin-top: 36px;
    flex-direction: column;
    align-items: center;
    
}
`

const IntroductionSectionBottom = () => {
    return (
        <MainDiv>
            {
                IntroductionSectionInfoProvider.items.map((model, idx) =>
                    <IntroductionSectionItem
                        model={model}
                        index={idx}
                    />
                )
            }
        </MainDiv>
    )
}

export default IntroductionSectionBottom