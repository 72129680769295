export default class IntroductionModel {
    title: string
    content: string
    image: string

    constructor(
        title: string = "",
        content: string = "",
        image: string = "",
    ) {
        this.title = title
        this.content = content
        this.image = image
    }

    static from(json: any) {
        return Object.assign(new IntroductionModel(), json);
    }
}