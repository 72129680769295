import { FC } from "react"
import styled from "styled-components"
import MovieDetailColumnModel from "../model/movie_detail_column_model"

const MainDiv = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
flex:1;
`
const ColumnTitle = styled.h4`
font: ${props => props.theme.smallTitleFont};
color: ${props => props.theme.white};
`
const ColumnText = styled.li`
font-size: 16px;
line-height: 24px;
font-weight: 400;
color: ${props => props.theme.silver};
margin-top: 3px;
`

interface ColumnProps {
    model: MovieDetailColumnModel
}

const MovieDetailColumn: FC<ColumnProps> = (props) => {
    return (
        <MainDiv>
            <ColumnTitle>
                {props.model.title}
            </ColumnTitle>
            <ul>
                {
                    props.model.nameList.map(e =>
                        <ColumnText>
                            {e}
                        </ColumnText>
                    )
                }
            </ul>

        </MainDiv>
    )
}

export default MovieDetailColumn

