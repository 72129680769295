const theme = {
    primaryColor: '#fff',
    blackColor: '#000000',
    white: '#fff',
    white_02: 'rgba(255, 255, 255, 0.2)',
    carmine: '#AF001E',
    fuelYellow: '#EAA52A',
    silver: '#BDBDBD',

    textShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    normalTitleFont: '700 32px "SSR"',
    smallTitleFont: '700 16px/24px "SSR"',
    bodyFont: '400 16px/24px "SSR"',
    serifFont: "SSRSerif",

    mainPadding: '65px'
}

export default theme