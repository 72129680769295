import React from "react"
import styled from "styled-components"

const SvgContainer = styled.div<{
    height?: number
    width?: number
}>`
  height: "auto";
  width: "auto";
  display: inline-flex;
  align-items: center;
  justify-content: center;

  & svg {
    height: ${props => (props.height ? `${props.height}px` : "100%")};
    width: ${props => (props.width ? `${props.width}px` : "unset")};
  }
`

interface Props {
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    width?: number
    height?: number
}

const SvgIcon: React.FC<Props> = props => {
    const { Icon } = props
    return (
        <SvgContainer {...props}>
            <Icon />
        </SvgContainer>
    )
}

export default SvgIcon