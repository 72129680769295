import { FC } from 'react'
import styled, { css } from 'styled-components'


const MainDiv = styled.button<ButtonProps>`
width: ${props => props.width};
height: ${props => props.height};
font-size: ${props => props.fontSize ?? "16px"};
font-weight: ${props => props.fontWeight ?? 900};
color: ${props => props.theme.white};
background: linear-gradient(91.77deg, #E31F2B 1.7%, #AF001D 85.65%);
border-radius: 6px;
align-self: ${props => props.alignSelf ?? "flex-start"};
cursor: pointer;
${props => props.isTransparent && css`
background: transparent;
border: 2px solid rgba(255, 255, 255, 0.5);
letter-spacing: 0.03em;
text-transform: none !important;
`}
`

interface ButtonProps {
    width: string
    height: string
    isTransparent?: boolean
    fontSize?: string
    fontWeight?: number
    text?: string
    alignSelf?: string
}


const GradientButton: FC<ButtonProps> = (props) => {

    return (
        <MainDiv
            width={props.width}
            height={props.height}
            isTransparent={props.isTransparent ?? false}
            alignSelf={props.alignSelf}
            fontSize={props.fontSize}
            fontWeight={props.fontWeight}
        >
            {props.text}
        </MainDiv>
    )
}
export default GradientButton
