enum ProductionTypeIconType {
    SIcon,
    RIcon,
    GIcon,
    FIcon,
    IIcon,
    WIcon,
    TIcon
}


export default ProductionTypeIconType