export default class MovieDetailColumnModel {
    title: string
    nameList: Array<string>

    constructor(
        title: string = "",
        nameList: Array<string> = [],
    ) {
        this.title = title
        this.nameList = nameList
    }

    static from(json: any) {
        return Object.assign(new MovieDetailColumnModel(), json);
    }
}