import styled from "styled-components"
import media from "../../../../utils/custom_media"
import FooterSectionBottom from "./components/footer_section_bottom"
import FooterSectionMiddle from "./components/footer_section_middle"
import FooterSectionTop from "./components/footer_section_top"
import MobileFooterSectionLanguageSelection from "./components/mobile_footer_section_language_selection"

const MainDiv = styled.section`
width: 100%;
background: linear-gradient(0deg, #1B0F0F, #1B0F0F), linear-gradient(97.88deg, #311437 6.1%, #730838 93.95%);
display: flex;
justify-content: center;
margin-top: 36px;
`
const InnerDiv = styled.div`
max-width: 1645px;
width: 100%;
display: flex;
flex:1;
flex-direction: column;
align-items: center;
padding: 40px 64px 28px 64px;
${media.phone}{
    padding: 40px 44px 28px 44px;
}
`

const FooterSectionMain = () => {
    return (
        <MainDiv>
            <InnerDiv>
                <FooterSectionTop />
                <FooterSectionMiddle />
                {/* <MobileFooterSectionLanguageSelection /> */}
                <FooterSectionBottom />
            </InnerDiv>
        </MainDiv>
    )
}

export default FooterSectionMain