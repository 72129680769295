import { useContext } from "react"
import styled from "styled-components"
import LanguageIcon from "../../../../../components/icons/language_icon"
import MovieIcon from "../../../../../components/icons/movie_icon"
import SoundOffIcon from "../../../../../components/icons/sound_off_icon"
import SubtitleIcon from "../../../../../components/icons/subtitle_icon"
import SizedBox from "../../../../../components/sizedbox"
import useWindowSize from "../../../../../hooks/useWindowSize"
import { MovieDetailStatusContext } from "../../../../../providers/movie_detail_status_provider"
import media from "../../../../../utils/custom_media"
import ScreenBreakpoints from "../../../../../utils/screen_breakpoints"

const MainDiv = styled.div`
width: 100%;
height: 50%;
display: flex;
flex-direction: column;
justify-content: flex-end;
align-items: center;
position: absolute;
bottom: 0;
right: 0;
left: 0;
background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
${media.phone}{
    padding: 0 15px;
}
`
const TitleText = styled.h1`
text-shadow: ${props => props.theme.textShadow};
font-weight: 900;
font-size: 44px;
color: ${props => props.theme.white};
${media.phone}{
   font-size: 32px;
}
`
const MiddleRow = styled.div`
display: flex;
align-items: center;
margin-top: 16px;
`
const AgeRating = styled.div`
width: 4px;
height: 12.67px;
opacity: 0.9;
background: ${props => props.theme.fuelYellow};
`
const MovieTypeText = styled.div`
text-shadow: ${props => props.theme.textShadow};
color: ${props => props.theme.white};
font-family: ${props => props.theme.serifFont};
font-size: 16px;
font-weight: 400;
line-height: 22px;
${media.phone}{
  font-size: 15px;
}
`
const Chip = styled.div`
background: rgba(50, 50, 50, 0.5);
border-radius: 60px;
display: flex;
align-items: center;
justify-content: center;
font-family: ${props => props.theme.serifFont};
font-size: 16px;
padding: 4px 8px;
margin-left: 8px;
color: ${props => props.theme.white};
${media.phone}{
  font-size: 15px;
}
`

const MovieDescription = styled.p`
max-width: 648px;
margin-top: 16px;
padding-bottom: 32px;
border-bottom: 1px solid ${props => props.theme.white_02};
text-shadow: ${props => props.theme.textShadow};
color: rgba(255, 255, 255, 0.7);
font-size: 16px;
line-height: 22px;
text-align: center;
`
const SeeMoreText = styled.span`
font-size: 16px;
line-height: 22px;
color: ${props => props.theme.white};
cursor: pointer;
user-select: none;
text-decoration: underline;
`
const SoundOffContainer = styled.div`
display: flex;
align-items: center;
position: absolute;
z-index: 1;
bottom: 169px;
right: 44px;
${media.phone}{
   display: none;
}
`
const SoundOffText = styled.span`
font-size: 16px;
line-height: 24px;
font-weight: 900;
color: ${props => props.theme.white};
margin-left: 10px;
`

const HeaderSectionBottom = () => {
    const detailStatusContext = useContext(MovieDetailStatusContext)
    const windowSize = useWindowSize()
    const isMobile = windowSize.width < ScreenBreakpoints.phone ? true : false
    const toggleDetailVisibility = () => {
        detailStatusContext.toggleDetailVisibility()
    }

    return (
        <MainDiv>
            <TitleText>
                Wilder
            </TitleText>
            <MiddleRow>
                <AgeRating />
                <SizedBox width="9.58px" />
                <MovieIcon />
                {
                    !isMobile &&
                    <>
                        <SizedBox width="5.58px" />
                        <MovieTypeText>
                            Movies
                        </MovieTypeText>
                    </>
                }
                <Chip>
                    1h 30m
                </Chip>
                <SizedBox width="8px" />
                <MovieTypeText>
                    Drame  •  Histoire
                </MovieTypeText>
                <Chip>
                    <LanguageIcon />
                    <SizedBox width="4px" />
                    <MovieTypeText>
                        De
                    </MovieTypeText>
                    <SizedBox width="9.33px" />
                    <SubtitleIcon />
                    <SizedBox width="4px" />
                    <MovieTypeText>
                        Fr
                    </MovieTypeText>
                </Chip>
            </MiddleRow>

            <MovieDescription>
                {`Ein Detektivin kehrt in ihren Heimatort in den Schweizer Bergen zurück, um sich an ihren Bruder zu erinnern, der vor vielen Jahren zusammen mit einigen anderen Kindern bei einem Unfall ums Leben kam. Nach einem Mord in der Stadt bleibt er und beginnt zu ermitteln. Nicht zuletzt durch die wunderschönen Alpentälern wurde die TV-Serie zum Hit in der Schweiz. `}
                <SeeMoreText
                    onClick={toggleDetailVisibility}
                >
                    Mehr sehen
                </SeeMoreText>
            </MovieDescription>

            <SoundOffContainer>
                <SoundOffIcon />
                <SoundOffText>
                    Ton ausschalten
                </SoundOffText>
            </SoundOffContainer>
        </MainDiv>
    )
}

export default HeaderSectionBottom