import MovieDetailColumnModel from "../model/movie_detail_column_model"

const MovieDetailInfoProvider = {
    columnList: [
        new MovieDetailColumnModel("Direktor(en)", ["Pierre Monard", "Jan-Eric Mack", "Samuel Perriard"]),
        new MovieDetailColumnModel("Produktion", ["SRF", "C-films", "Panimage"]),
        new MovieDetailColumnModel("Skript", ["Béla Batthyany",
            "Moritz Gerber",
            "Roberto Martinez",
            "André Kuettel",
            "Alexander Seibt",
            "Andreas Stadler",
            "Dave Tucker",
            "Alexander Szombath"]),
        new MovieDetailColumnModel("Cast", ["Sarah Spale",
            "Andreas Matti",
            "Marcus Signer",
            "Ruth Schwegler",
            "Roland Bonjour",
            "Andreas Grötzinger",
            "Michael Neuenschwander",
            "Sarah Hostettler",
            "Annina Butterworth"]),
        new MovieDetailColumnModel(" ", ["Sarah Spale",
            "Andreas Matti",
            "Marcus Signer",
            "Ruth Schwegler",
            "Roland Bonjour",
            "Andreas Grötzinger"]),
    ]
}

export default MovieDetailInfoProvider