import { FC } from "react"
import styled from "styled-components"

const MainDiv = styled.section`
width: 100%;
max-width: 1440px;
position:relative;
`
interface PageLayoutProps {
    children: React.ReactNode
}

const PageLayout: FC<PageLayoutProps> = (props) => {
    return (
        <MainDiv>
            {props.children}
        </MainDiv>
    )
}

export default PageLayout