import styled from "styled-components"
import SocialMediaIcon from "../../../../../components/icons/social_media_icon"
import SocialMediaIconType from "../../../../../enums/social_media_icon_type"
import useWindowSize from "../../../../../hooks/useWindowSize"
import media from "../../../../../utils/custom_media"
import ScreenBreakpoints from "../../../../../utils/screen_breakpoints"

const MainDiv = styled.div`
display: flex;
flex-direction: column;
padding-top: 48px;
padding-left: 65px;
align-items: flex-start;
width: 100%;
${media.tablet}{
    padding: 40px 15px 13px 15px;
}
`
const MoreDetailsTitle = styled.h2`
font-size: 32px;
color: ${props => props.theme.white};
font-weight: 700;
`
const MovieDetailRow = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
max-width: 790px;
margin-top: 16px;
`
const DetailContainer = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
${media.phone}{
:not(:first-child){
    margin-top: 12px;
}
}
`
const DetailTitle = styled.h4`
font: ${props => props.theme.smallTitleFont};
color: ${props => props.theme.white};
`
const DetailInfoText = styled.span`
font-size: 16px;
line-height: 24px;
font-weight: 400;
color: ${props => props.theme.silver};
margin-top: 4px;
`
const SocialMediaIconsContainer = styled.div`
display: flex;
align-items: center;
flex-wrap: wrap;
margin-top: 4px;
svg{
    :not(:first-child){
        margin-left: 20px;
    }
}
${media.phone}{
    svg{
    :not(:first-child){
        margin-left: 21.84px;
    }
}
}
`
const DetailParagraph = styled.p`
max-width: 980px;
font: ${props => props.theme.bodyFont};
margin-top: 24px;
color: ${props => props.theme.white};
${media.phone}{
    margin-top: 12px;
    opacity: 0.7;
}
`

const MovieDetailSectionTop = () => {

    const windowSize = useWindowSize()

    const buildAboutColumn = () => {
        return (
            <DetailContainer>
                <DetailTitle>
                    Über
                </DetailTitle>
                <DetailInfoText>
                    3 stagger (2017-2020)
                </DetailInfoText>
            </DetailContainer>
        )
    }

    const buildGenresColumn = () => {
        return (
            <DetailContainer>
                <DetailTitle>
                    Genres
                </DetailTitle>
                <DetailInfoText>
                    Thriller, Crime, Mystery, Drama
                </DetailInfoText>
            </DetailContainer>
        )
    }
    const buildFollowColumn = () => {
        return (
            <DetailContainer>
                <DetailTitle>
                    Verfolgen Sie die Sendung
                </DetailTitle>
                <SocialMediaIconsContainer>
                    <SocialMediaIcon
                        iconType={SocialMediaIconType.Facebook}
                    />
                    <SocialMediaIcon
                        iconType={SocialMediaIconType.Twitter}
                    />
                    <SocialMediaIcon
                        iconType={SocialMediaIconType.Youtube}
                    />
                </SocialMediaIconsContainer>
            </DetailContainer>
        )
    }

    return (
        <MainDiv>
            <MoreDetailsTitle>
                Mehr Details
            </MoreDetailsTitle>
            <MovieDetailRow>
                {
                    buildAboutColumn()
                }
                {
                    windowSize.width > ScreenBreakpoints.phone &&
                    buildGenresColumn()
                }
                {
                    windowSize.width > ScreenBreakpoints.phone &&
                    buildFollowColumn()
                }
            </MovieDetailRow>
            <DetailParagraph>
                {`Die Schweizer Krimiserie "Wilder" handelt von der Kantonspolizistin Rosa Wilder (Sarah Spale), die mit dem einsamen Bundeskriminalbeamten Manfred Kägi (Marcus Signer) und dem jungen Kollegen Jamel zusammenarbeitet. Beschreibung Göschenen 1873. Es herrscht Goldgräberstimmung. Die Bauarbeiten am Eisenbahntunnel durch den Gotthard locken Hunderte von Menschen aus allen... Beschreibung Göschenen 1873. Es herrscht Goldgräberstimmung. Die Bauarbeiten an der.`}
            </DetailParagraph>
            {
                windowSize.width <= ScreenBreakpoints.phone &&
                buildGenresColumn()
            }
            {
                windowSize.width <= ScreenBreakpoints.phone &&
                buildFollowColumn()
            }
        </MainDiv>
    )
}

export default MovieDetailSectionTop