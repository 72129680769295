import { FC } from "react"
import styled from "styled-components"

const MainDiv = styled.div<{ height: string, width: string }>`
height: ${props => props.height};
width: ${props => props.width};
background: ${props => props.theme.carmine};
display: flex;
align-items: center;
justify-content: center;
:not(:first-child){
    margin-left: 16px;
}
`

interface ProductionTypeProps {
    height: string
    width: string
    children: React.ReactNode
}

const ProductionType: FC<ProductionTypeProps> = (props) => {
    return (
        <MainDiv
            height={props.height}
            width={props.width}
        >
            {props.children}
        </MainDiv>
    )
}

export default ProductionType