import { FC } from "react"
import styled from "styled-components"
import MostWatchedSectionModel from "../model/most_watched_section_model"

const MainDiv = styled.div`
width: 100%;
cursor: pointer;
display: flex;
position: relative;
:hover .filterDiv{
    opacity:1;
}
`
const FilterDiv = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-end;
padding-bottom: 10px;
opacity: 0;
background: rgba(0, 0, 0, 0.6);
transition: opacity 0.5s;
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
`
const TextContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`
const TitleText = styled.div`
font-size: 13px;
line-height: 22px;
font-weight: 700;
color: ${props => props.theme.white};
`
const TypeText = styled.div`
font-size: 13px;
line-height: 24px;
font-weight: 400;
color: ${props => props.theme.silver};
margin-top: 4.75px;
`
interface MostWatchedItemProps {
    model: MostWatchedSectionModel
}

const MostWatchedItem: FC<MostWatchedItemProps> = (props) => {
    return (
        <MainDiv

        >
            <img src={props.model.image} alt={`${props.model.title}_image`} />
            {
                props.model.title.length > 0 &&
                <FilterDiv className="filterDiv" >
                    <TextContainer>
                        <TitleText>{props.model.title}</TitleText>
                        <TypeText>{props.model.type}</TypeText>
                    </TextContainer>
                </FilterDiv>
            }
        </MainDiv>
    )
}

export default MostWatchedItem