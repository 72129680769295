class MovieDetailModel {
    name?: string;
    duration?: string;
    description?: string;
    descriptionLong?: string;
    genres?: string[];
    about?: string;
    directors?: string[];
    production?: string[];
    script?: string[];
    cast?: string[];
    image?: string;

    constructor(
        name: string = "",
        duration: string = "",
        description: string = "",
        descriptionLong: string = "",
        genres: string[] = [],
        about: string = "",
        directors: string[] = [],
        production: string[] = [],
        script: string[] = [],
        cast: string[] = [],
        image: string = "",
    ) {
        this.duration = duration
        this.name = name
        this.description = description
        this.descriptionLong = descriptionLong
        this.genres = genres
        this.about = about
        this.directors = directors
        this.production = production
        this.script = script
        this.cast = cast
        this.image = image
    }
}

export default MovieDetailModel