const ScreenBreakpoints = {
    phone: 576,
    tablet: 768,
    desktop: 992,
    largeDesktop: 1200
}

export default ScreenBreakpoints



