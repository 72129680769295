import styled from "styled-components"
import HeaderBackgroundImage from "../../../../assets/jpg/wilder_bild_1.jpg"
import HeaderMobileBackgroundImage from "../../../../assets/jpg/wilder_bild_1_mobile.jpg"
import media from "../../../../utils/custom_media"
import HeaderSectionBottom from "./components/header_section_bottom"

const MainDiv = styled.section`
width: 100%;
height: 800px;
background: radial-gradient(96.69% 290.85% at -2.45% 40.03%, #000000 0%, rgba(0, 0, 0, 0) 80.73%);
/* background-size: cover;
background-repeat: no-repeat;
background-position: center; */
display: flex;
flex-direction: column;
justify-content: flex-end;
/* ${media.phone}{
    background-image: url(${HeaderMobileBackgroundImage});
} */
`

const StyledMobileImage = styled.img`
object-fit: cover;
width: 100%;
height: 100%;
display: none;
${media.phone}{
    display: block;
}
`
const StyledDesktopImage = styled.img`
object-fit: cover;
width: 100%;
height: 100%;
display: block;
${media.phone}{
    display: none;
}
`

const HeaderSectionMain = () => {

    return (
        <MainDiv>
            <StyledMobileImage src={HeaderMobileBackgroundImage} alt="wilder_tv_serie_bild_1" />
            <StyledDesktopImage src={HeaderBackgroundImage} alt="wilder_tv_serie_bild_1" />
            <HeaderSectionBottom />
        </MainDiv>
    )
}

export default HeaderSectionMain